<template>
  <div class="buy-detail">
    <div class="top-banner">
      <img style="width: 100%;height: 50vh" src="@/assets/page4/banner.png" alt="">
      <div class="banner-title">砥砺前行十余载 踔厉奋发向未来</div>
    </div>
    <div class="project-main">
      <div class="info-main">
        <div class="info-title">
          <div>{{ projectInfo.bidName }}</div>
          <div>
            <div class="buy-status" v-if="projectInfo.procureState==0"><img src="@/assets/page4/1.png" alt=""><div>开标</div></div>
            <div class="buy-status" v-if="projectInfo.procureState==1"><img src="@/assets/page4/2.png" alt=""><div>招标</div></div>
            <div class="buy-status" v-if="projectInfo.procureState==2"><img src="@/assets/page4/3.png" alt=""><div>中标</div></div>
          </div>
        </div>
        <div class="info-time">
          <span>发布时间: {{ projectInfo.updateTime }}</span>
          <span style="margin-left: 2vw;">浏览量：{{ projectInfo.readCount }}</span>
        </div>
        <div class="info-content " style="text-align: justify;">
          <div class="ql-editor" v-html="projectInfo.tenderContent" style="width: 100%;overflow: auto;"></div>
        </div>
      </div>
      <div class="info-more">
        <div>
          <span>征集人：</span><span>{{ projectInfo.collecter }}</span>
        </div>
        <div style="margin: 1vh 0;">
          <span>地址：</span><span>{{ projectInfo.address }}</span>
        </div>
        <div>
          <span>联系人：</span><span>{{ projectInfo.contactor }}</span>
        </div>
      </div>
    </div>
    <div class="prv-next">
      <div class="prv-text" @click="goNext(preNextData.beforeId)">
        <span>上一篇：</span><span class="other-news">{{ preNextData.beforeTitle ? preNextData.beforeTitle : '没有了' }}</span>
      </div>
      <div class="next-text" @click="goNext(preNextData.afterId)">
        <span>下一篇：</span><span class="other-news">{{ preNextData.afterTitle ? preNextData.afterTitle : '没有了' }}</span>
      </div>
      <div class="return-button" @click="goBack">返回列表</div>
    </div>
  </div>
</template>

<script>
import { getProCureDetail, getProCureBeforeAndAfter } from "../../../js/api/index";
export default {
  data() {
    return {
      projectInfo: '',
      preNextData: '',
    };
  },
  computed: {},
  methods: {
    checkRouter() {
      let id = this.$route.params.id;
      getProCureDetail(id).then(res => {
        this.projectInfo = res.data;
      })
      getProCureBeforeAndAfter(id).then(res => {
        if(!res.data){
          this.preNextData = {
            beforeTitle:null,
            afterTitle:null,
            beforeId:null,
            afterId:null
          }
        }else{
          this.preNextData = res.data;
        }
      })
    },
    goNext(id) {
      if (!id) return;
      this.$router.replace({ params: { id } }) // 只能用 name
      this.checkRouter();
    },
    goBack(){
      this.$router.push({ path: "/business" });
    },
  },
  mounted() {
    this.checkRouter();
  }
};
</script>

<style lang="scss" scoped>
.top-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .banner-title {
    position: absolute;
    font-size: 2vw;
    font-weight: 800;
    color: #FFFFFF;
  }
}
.buy-detail{
  background: #F3F4F4;
}
.return-button{
  cursor: pointer;
  position: absolute;
  width: 9.27vw;
  height: 6.4vh;
  background: #004DA0;
  font-size: .9vw;
  font-weight: 500;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 22vw;
}

.project-main {
  padding-left: 18.75vw;
  padding-right: 18.75vw;
  padding-top: 5.28vh;
  .info-main{
    .info-title{
      font-size: 1.8vw;
      font-weight: bold;
      color: #333333;
      padding-bottom: 2vh;
      margin-top: 2vh;
      border-bottom: 1px solid #DCDCDC;
      display: flex;
      justify-content: center;
    }
    .info-time{
      font-size: .9vw;
      color: #333333;
      margin: 2vh 0;
    }
  }
}
.prv-next{
  text-align: left;
  height: 15.4vh;
  padding-left: 20vw;
  padding-right: 20vw;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  .other-news{
    cursor: pointer;
    transition: all ease .2s;
  }
  .other-news:hover{
    color: #004DA0;
  }
}
.prv-text{
  margin-bottom: 2vh;
}
.buy-status{
  width: 4.2vw;
  height: 3vh;
  position: relative;
  margin-left: 1vw;
  img{
    width: 100%;
    height: 100%;
  }
  >div{
    font-weight: bold;
    color: #FFFFFF;
    font-size: .8vw;
    position: absolute;
    top: 45%;
    width: 100%;
  }
}
.info-more{
  background: #FFFFFF;
  text-align: left;
  padding: 3vh 2vw;
}
</style>